import { DatabaseSwitcherSkeleton } from '@/components/layouts/sidebar/database-switcher/database-switcher-skeleton';
import React from 'react';

export function SkeletonHeader() {
  return (
    <div className="flex px-4 py-3 justify-between items-center grid-in-header border-b border-ui-03 h-16">
      <div className="flex sm:gap-4">
        <div className="flex w-14 sm:w-52 md:w-56 shrink-0">
          <DatabaseSwitcherSkeleton />
        </div>
        <div className="flex-1 items-center flex shrink-0">
          <div className="h-8 w-52 md:w-[420px] rounded-full bg-skeleton-text animate-pulse" />
        </div>
      </div>
      <div className="ml-2 flex items-bottom">
        <div className="w-8 h-8 rounded-full bg-skeleton-graphic animate-pulse" />
      </div>
    </div>
  );
}
