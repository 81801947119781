import React from 'react';
import { sidebarNavigation } from '@/core/utils';
import { NavItem } from '@/components/skeletons/nav-item';

export function SkeletonSidebar() {
  return (
    <div className="pt-3 pb-4 flex flex-col">
      <div className="grow flex flex-col">
        <nav className="flex-1 bg-white px-2 space-y-1 mb-4" data-testid="sidebar-navigation">
          {sidebarNavigation.map((item) => (
            <NavItem key={item.name} />
          ))}
        </nav>
        <div className="border-b border-ui-02 mx-2" />
        <div className="p-4" data-testid="skeleton-storage-panel">
          <div className="flex flex-row gap-3">
            <div className="h-5 w-6 rounded-full bg-skeleton-graphic animate-pulse invisible md:visible" />
            <div className="h-5 w-full rounded-full bg-skeleton-text animate-pulse invisible md:visible" />
          </div>
        </div>
      </div>
    </div>
  );
}
