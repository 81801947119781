import { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { useRouter } from 'next/router';

import { LoginSkeleton } from '@/components/login-skeleton';
import { useLogin } from '@/core/hooks';

export const getServerSideProps: GetServerSideProps = async (context) => {
  return {
    props: {
      token: context.query.token || null,
      folder_id: context.query.folder_id || null
    }
  };
};

export default function Login({
  token,
  folder_id
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const { push } = useRouter();
  const { isSuccess } = useLogin({ token });

  if (isSuccess && folder_id) {
    push(`/home?folder_id=${folder_id}`);
  } else if (isSuccess) {
    push('/home');
  }

  return <LoginSkeleton />;
}
