import { ComponentProps } from 'react';

export default function SkeletonTable(props: ComponentProps<'div'>) {
  return (
    <div {...props}>
      <table className="divide-y-2 divide-y-ui-04 border-b border-ui-03 table w-full">
        <thead className="text-left divide-y-2 divide-ui-03">
          <tr>
            <th className="align-top body-01 py-3">
              <div className="bg-skeleton-text h-5 w-1/4 flex gap-4 items-center rounded-md animate-pulse" />
            </th>
            <th className="align-top body-01 py-3">
              <div className="bg-skeleton-text h-5 w-1/4 flex gap-4 items-center rounded-md animate-pulse" />
            </th>
            <th className="align-top body-01 py-3">
              <div className="bg-skeleton-text h-5 w-1/4 flex gap-4 items-center rounded-md animate-pulse" />
            </th>
            <th className="align-top body-01 py-3">
              <div className="bg-skeleton-text h-5 w-1/4 flex gap-4 items-center rounded-md animate-pulse" />
            </th>
            <th className="align-top body-01 py-3">
              <div className="bg-skeleton-text h-5 w-1/4 flex gap-4 items-center rounded-md animate-pulse" />
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-y-ui-04 body-01">
          <tr className="text-left">
            <td className="py-4">
              <div className="h-8 w-10/12 bg-skeleton-text rounded-md animate-pulse" />
            </td>
            <td className="py-4">
              <div className="h-8 w-1/2 bg-skeleton-text rounded-md animate-pulse" />
            </td>
            <td className="py-4">
              <div className="h-8 w-1/2 bg-skeleton-text rounded-md animate-pulse" />
            </td>
            <td className="py-4">
              <div className="h-8 w-1/2 bg-skeleton-text rounded-md animate-pulse" />
            </td>
            <td className="py-4">
              <div className="h-8 w-1/2 bg-skeleton-text rounded-md animate-pulse" />
            </td>
          </tr>
          <tr className="text-left">
            <td className="py-4">
              <div className="h-8 w-10/12 bg-skeleton-text rounded-md animate-pulse" />
            </td>
            <td className="py-4">
              <div className="h-8 w-1/2 bg-skeleton-text rounded-md animate-pulse" />
            </td>
            <td className="py-4">
              <div className="h-8 w-1/2 bg-skeleton-text rounded-md animate-pulse" />
            </td>
            <td className="py-4">
              <div className="h-8 w-1/2 bg-skeleton-text rounded-md animate-pulse" />
            </td>
            <td className="py-4">
              <div className="h-8 w-1/2 bg-skeleton-text rounded-md animate-pulse" />
            </td>
          </tr>
          <tr className="text-left">
            <td className="py-4">
              <div className="h-8 w-10/12 bg-skeleton-text rounded-md animate-pulse" />
            </td>
            <td className="py-4">
              <div className="h-8 w-1/2 bg-skeleton-text rounded-md animate-pulse" />
            </td>
            <td className="py-4">
              <div className="h-8 w-1/2 bg-skeleton-text rounded-md animate-pulse" />
            </td>
            <td className="py-4">
              <div className="h-8 w-1/2 bg-skeleton-text rounded-md animate-pulse" />
            </td>
            <td className="py-4">
              <div className="h-8 w-1/2 bg-skeleton-text rounded-md animate-pulse" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
