import { SkeletonHeader } from './layouts/header/skeleton-header';
import { SkeletonSidebar } from './layouts/sidebar/skeleton-sidebar';
import { SkeletonContent } from './layouts/skeleton-content';

export function LoginSkeleton() {
  return (
    <div className="flex-col h-screen bg-ui-background" data-testid="login-skeleton">
      <SkeletonHeader />
      <div className="flex">
        <div className="border-r border-ui-03 grid-in-sidebar w-14 sm:w-52 md:w-56 shrink-0">
          <SkeletonSidebar />
        </div>
        <main className="focus:outline-none w-full grid-in-main">
          <SkeletonContent />
        </main>
      </div>
    </div>
  );
}
